<template lang="pug">
main
    .main-container
        .logo-container
            img(:src="require('@/assets/img/logo-solo.svg')",alt="Time Square Logo")
        .welcome-text WELCOME TO TIME SQUARE CLUB, RESORT & SPA
        h1 Refund & Cancellation Policy
    .container <b>Eligibility:</b><br/>Our refund policy only applies to sale done through the time square club website.
        <br/>
        <br/><b>Process:</b><br/>To initiate a refund, the customer must contact time square club customer support email hello@timesquare.club and provide a detailed explanation for the request. If the request is approved, time square club will provide instructions for returning the product.
        <br/>
        <br/><b>Cancellation/Refund:</b><br/>All non- guaranteed reservations will be released by 18:00 hrs before 72 hrs the date of arrival. Airport or railway station transfers are arranged at an additional cost, unless specified as above.
        <br/>The hotel requires at least 72 hours prior notice to cancel the reservation upto 5 Rooms any cancellation received after this will be 100% retention charges applicable.
        <br/>The hotel requires at least 7 days prior notice to cancel the reservation above 5 Rooms any cancellation, received after this will be 100% retention charges applicable.
        <br/>
        <br/><b>Limitation of Liability:</b><br/>time square club will not be liable for any indirect, incidental, or consequential damages arising from the use of its products or the application of its refund policy.
        <br/>
        <br/><b>Changes to Policy:</b><br/>time square club reserves the right to make changes to its refund policy at any time without notice.

</template>
        
<script>

import DisplayHeader from '../components/DisplayHeader.vue'

export default {
    name: "TermsConditions",
    components: {
    DisplayHeader
    },
    mounted() {
        window.scroll(0,0);
    }
}
</script>

<style scoped>
.main-container {
    color: black;
    background-color: #d7d5d0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    margin-bottom: 80px;
}

.logo-container img {
    width: 3rem;
    margin: 1rem;
}

.welcome-text {
    margin: 1rem;
    margin-top: 0;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    opacity: 0.5;
    font-weight: 400;
}

h1 {
    margin: 0;
    width: 80%;
    font-family: "DM Serif Display",serif;
    font-size: 3rem;
    text-align: center;
    font-weight: 400;
}
</style>